.slider-control-container {
    display: flex;
    padding: 0px 5px;
    .option-color {
      //  margin-top: 1px;
      position: relative;
      top: 0.05rem;
      font-size: 90%;
        color: #aaa;
        transition: all 100ms ease-in;
        &.selected {
            color: cyan;
        }

    }
    .switch {
        margin-right: 5px;
    }
}