.grid-display {
    pointer-events: none;
//background: white;
        .grid-display-svg {
            pointer-events: none;
            // width: calc(100% - 10px);
            // height: calc(100% - 10px);
            // outline: 1px solid gray;
            // box-sizing: border-box;
        }
    
}
