.install-svg {
    display: inline-block;
    background: #eee;
    margin: 2px 3px;
    padding: 0px 20px 20px 20px;
    user-select: text;
    width: 700px;
    .install-svg-top {
        margin-top: 5px;
        padding: 8px 2px 0 2px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        .title {
            font-size: clamp(18px, 1.8vw, 20px);
            font-weight: bold;
        }
        .close-button {
            height: 28px;
            position: relative;
        }
    }
    .intro {
        font-size: clamp(17px, 1vw, 20px);
    }
    .install-contain {
        height: 222px;
        overflow: hidden;
        .install {
            .svg-installed {
                padding: 0 20px 0 10px;
            }
            background-color: #e0efff;
            //margin: 20px;
            border: 1px solid gray;
            display: inline-flex;
            align-items: center;
            height: 220px;
            // padding: 10px;
            .svg-container {
                overflow: hidden;
                display: flex;
                align-items: center;
                height: 100%;
                margin: 10px;
                width: 200px;
                min-width: 200px;
                height: 200px;
                text-align: center;
                border: 1px solid gray;
                &.installed {
                    border: none;
                }
                &.svgLoadError {
                    filter: sepia(100%) saturate(100%) brightness(80%) hue-rotate(280deg);
                }


                .background-for-svg {
                    margin: auto auto;
                    position: relative;
                    background-image: /* tint image */ linear-gradient(
                            to right,
                            rgba(192, 192, 192, 0.75),
                            rgba(192, 192, 192, 0.75)
                        ),
                        /* checkered effect */ linear-gradient(to right, #777 50%, #eee 50%),
                        linear-gradient(to bottom, #777 50%, #eee 50%);
                    background-blend-mode: normal, difference, normal;
                    background-size: 1em 1em;
                    .import-svg {
                        width: 200px;
                        height: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &.installed {
                        background-image: none;
                    }
                }
            }

            .svg-loaded-error {
                padding: 0 10px;
            }

            .input-svg-select {
                justify-content: center;
                align-self: center;
                margin: 0 20px;
                .already-used {
                    padding: 10px 10px 5px 10px;
                    margin-bottom: 13px;
                    border: 1px solid #b77;
                    background-color: #fcc;
                    span {
                        color: #01a;
                        text-shadow: 0 0 #03f;
                        font-size: 110%;
                    }
                }
                div {
                    padding: 0 0 5px;
                }
                input[type="file"] {
                    margin-top: 10px;
                }
                .create-layer {
                    padding: 5px;
                    border: 1px solid #77f;
                    background: rgba(90, 110, 255, 0.2);
                    p {
                        margin: 6px;
                    }
                    .input-items {
                        margin-top: 11px;
                        button {
                            margin-left: 10px;
                        }
                    }
                }
                .install-custom-svg {
                    margin-top: 5px;
                    input[type="text"] {
                        margin-right: 10px;
                    }
                    .name-taken {
                        color: red;
                        margin-top: 5px;
                    }
                }

                .adjust-viewbox {
                    border: 1px solid purple;
                    padding: 10px 10px 0 10px;
                    background-color: rgb(253, 221, 255);
                    .current-viewbox {
                        margin-top: 1px;
                        font-size: 90%;
                        color: #005511;
                        text-align: center;
                        span {
                            margin-left: 10px;
                            color: rgb(55, 55, 255);
                            text-decoration: underline;
                            cursor: pointer;
                            &.no-change {
                                color: #aaa;
                                text-decoration: none;
                                cursor: default;
                                &:hover {
                                    color: #aaa;
                                }
                            }
                            &:hover {
                                color: red;
                            }
                        }
                    }
                    .viewbox-controls {
                        margin: 5px auto;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        input[type="text"] {
                            margin-right: 10px;
                            width: 50px;
                            &.invalid {
                                background: #ffcccc;
                            }
                        }
                    }
                }
            }
        }
    }
    .other-options {
        display: flex;
        button {
            margin: 10px;
        }
    }
}
