.save-counter {
    position: relative;

    .dialog {
        position: absolute;
        background-color: black;
        top: 30px;
        border: 1px solid rgba(0, 255, 255, 0.5);
        padding: 0 0 10px 0;
        z-index: 1;
        font-size: 110%;
       
        background-image: linear-gradient(#000, #001, #002);
        //transition: visibility 0s, opacity 0.5s linear;
        .apply-date-time {
            text-align: center;
            font-size: 90%;
            color: #0ff;
            padding: 9px 0 5px 0px;
            cursor: pointer;
        }
        .save-input-inline {
            padding: 0px 0 16px 0px;
            display: flex;
            white-space: nowrap;
            align-items: center;
            justify-content: center;
            div:first-child {
                margin-right: 5px;
            }
            input[type=text] {
                width: 320px;
            }
        }
        .asterisk-explanation {
           text-align: center;
           padding: 10px 0 5px 0;
           color: #ccc;
           &::before {
            content:'*';
            position: relative;
            top: 2px;
            margin-right: 3px;
            font-size: 130%;
            color: #f33;
            text-shadow: 1px 1px rgba(255,255,0,0.3);
        }
        }

        .save-counter-options-container {
            border-radius: 10px;
            padding: 20px auto;
            // width:400px;
            // height:400px;
            display: grid;
            grid-template-columns: 220px 220px;
            grid-row: auto auto;
            grid-column-gap: 20px;
            grid-row-gap: 10px;

            .save-counter-option {
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                span {
                    position: relative;
                    top: 2px;
                    color: #f33;
                    font-size: 110%;
                    line-height: 90%;
                    margin-left: 3px;
                }

            }


        }


    }
    .saved-counter-feedback {
        position: absolute;
        top: 35px;
        left: 0;
        color: #dfd;
        border: 1px solid black;
        white-space: nowrap;
        background: rgba(255,255,255,0.1);
        animation: fadein 0.3s;
        animation-fill-mode: forwards;
        animation: fadeInFromNone 0.1s ease-out;
    }

}


