.input-radio-group {
    display: flex;
    justify-content: center;
    //line-height: 17px;
    .named {
        font-size: 110%;
        //line-height: 100%;
        margin-right: 7px;
        white-space: nowrap;
        position: relative;
        top: -2px;
    }
    .radio-options {
        font-size: 110%;
        display: flex;
        div {
            padding: 0 6px 0 0;
            input {
                position: relative;
                top: -1px;
                vertical-align:middle;
            }
            label {
                margin-left: 2px;
            }
            &:first-child {
                label {
                    margin-left: 4px;
                }
            }
        }
        // & > :nth-child(1) {
        //     label {
        //        // margin-left: 4px;
        //         color: green;
        //         // position: relative;
        //         //left: -6px;
        //     }
        // }
    }
    .value-readout {
        font-size: 110%;
        line-height: 18px;
        border: 1px solid black;
        text-align: right;
        padding: 0 2px 0 1px;
        color: #ccf;
        min-width: 31px;
        width: 31px;
        &.percent {
            min-width: 42px;
        }
        &.min-width {
            min-width: 22px;
            width: 22px;
        }
    }
    .slider {
        width: 200px;
    }
}
