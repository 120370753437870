.server {
    .opener {
        cursor: pointer;
    }
    &.opened {
        .drop-down {
            display: block;
        }
    }
    .drop-down {
        display: none;
        margin-top: 1px;
        border-top: 1px solid #777;
        position: absolute;
        background-color: #e0e9ff;
        white-space: nowrap;
        z-index: 1;
        div {
            border-top: 1px solid #ddd;
            span {
                display: inline-block;
                padding: 3px 5px 3px 5px;
                pointer-events: none;
                &.alert {
                    color: red;
                    text-shadow: 0 0 red;
                }
            }
            &:hover {
                background: #85fcff;
            }
        }
    }
    .server-dialog {
        position: absolute;
        z-index: 1;
        border: 3px solid white;
        background: white;
        width: 500px;
        left: 4px;
        top: 20px;
        div.restore {
            padding: 5px 9px;
            background: #ffc3c3;
            text-shadow: 0 0 black;
            color: black;
            .warning-title {
                padding-top: 14px;
                font-size: 120%;
            }
            ul {
                margin: 0 0 0 30px;
                padding: 10px;
            }
            li {
                padding: 2px 0;
            }
            .action-buttons {
                margin: 10px 0 14px;
                display: flex;
                justify-content: space-around;
            }
            .option-title {
                text-shadow: 0 0 black;
                font-size: 105%;
                padding: 0 0 4px 0;
                border-bottom: 1px solid black;
            }
        }
        div.update {
            background: #dff;
            padding: 5px 9px;
            text-shadow: 0 0 black;
            color: black;
            div.update-text {
                padding: 14px 0 10px;
                height: 55px;
            }
            ul {
                margin: 0 0 0 30px;
                padding: 10px;
            }
            li {
                padding: 2px 0;
            }
            .action-buttons {
                margin: 10px 0 14px;
                display: flex;
                justify-content: space-around;
            }
            .option-title {
                text-shadow: 0 0 black;
                font-size: 115%;
                padding: 0 0 4px 0;
                border-bottom: 1px solid black;
                margin-bottom: 11px;
            }
            .updates-available {
                margin: 5px 0 0 0;
            }
            .update-info {
                margin: 5px 0 0 0;
                padding: 2px 2px 2px 5px;
                color: black;
                background-color: rgb(250, 233, 200);
                border: 1px solid rgb(220, 213, 180);
                font-size: 90%;
                div:nth-child(2) {
                    color: blue;
                    font-style: italic;
                    margin: 0 5px;
                }
                &.svg {
                    background-color: rgb(245, 250, 187);
                    border: 1px solid rgb(220, 213, 180);
                    margin: 8px 0 20px 0;
                }
            }
        }
    }
}
