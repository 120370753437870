.sheet-control {
    margin: 5px 0 0 0;
    > div:first-child {
        text-align: left;
        font-weight: bold;
        font-size: 120%;
        display: flex;
        cursor: pointer;
        &:hover {
            color: #05c;
        }
        .sheet-controls-control {
            font-size: 130%;
            margin-left: 5px;
            position: relative;
            top: -3px;
        }
    }
    .controls {
        margin-top: 3px;
        display: flex;

        .control {
            margin-right: 10px;
            .control-title {
                background: rgb(170, 204, 204);
                background: linear-gradient(
                    0deg,
                    rgba(170, 224, 224, 1) 0%,
                    rgba(187, 255, 255, 1) 50%,
                    rgba(221, 255, 255, 1) 100%
                );
                display: inline-block;
                position: relative;
                margin-right: 100%;
                white-space: nowrap;
                border-top-right-radius: 10px;
                border-top: 1px solid gray;
                border-right: 1px solid gray;
                border-left: 1px solid gray;
                padding: 2px 0;
                position: relative;
                top: 1px;
                font-size: 90%;
                text-shadow: 0 0 rgba(0, 0, 0, 0.5);
                color: #002;
                letter-spacing: 0.02rem;
                span {
                    margin: 0 10px;
                }
            }
            .options-container {
                .options {
                    background-color: #cff;
                    white-space: nowrap;
                    border: 1px solid gray;
                    padding: 1px;
                    &.counter-size {
                        text-align: left;
                        select {
                            margin-left: 4px;
                            &.unused {
                                background: rgba(0, 0, 0, 0.2);
                                color: rgba(0, 0, 0, 0.7);
                            }
                        }
                    }
                    input[type="text"].mm {
                        width: 40px;
                        margin-right: 3px;
                        margin-top: 2px;
                        &.warning {
                            background: #fdd;
                        }
                    }
                    .option {
                        padding: 3px;
                        &.use-custom {
                            text-align: center;
                        }
                        &:first-child {
                            padding-top: 7px;
                        }
                        .option-title {
                            margin-right: 5px;
                        }
                        select {
                            min-width: 40px;
                        }

                        .subscript {
                            font-size: 90%;
                            position: relative;
                            top: 2px;
                        }
                        .warning {
                            background-color: rgba(255, 210, 210, 0.9);
                        }
                        .counter-custom-size {
                            width: 40px;
                            margin-right: 3px;
                            &.unused {
                                background: rgba(0, 0, 0, 0.2);
                                color: rgba(0, 0, 0, 0.7);
                                &.warning {
                                    background: rgba(200, 0, 0, 0.3);
                                }
                            }
                        }
                    }
                    .two-by-two {
                        .row {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            width: 100%;
                        }
                        .center-column {
                            margin: auto;
                        }
                        .column {
                            display: flex;
                            flex-direction: column;
                            flex-basis: 100%;
                            flex: 1;
                            width: 140px;
                        }
                    }

                    // <div className="grid">

                    // <div className="row">
                    //     <div className='column'>
                    //         <span className="option-title">top:</span>
                    //         <input type="text" id="customWidth" className="mm" /><span className="subscript">mm</span>
                    //     </div>
                    //     <div className='column'>
                    //         <span className="option-title">bottom:</span>
                    //         <input type="text" id="customWidth" className="mm" /><span className="subscript">mm</span>
                    //     </div>
                    // </div>

                    .grid {
                        //display: flex;
                        .row {
                            display: flex;
                            padding: 0px;

                            > div:nth-child(2) {
                                align-items: flex-end;
                                margin-left: auto;
                            }
                            > div:nth-child(1) {
                                align-items: flex-start;
                            }

                            .column {
                                padding: 4px 5px;
                                .option-title {
                                    margin-right: 3px;
                                }
                            }
                            &.reset-apply {
                                display: flex;
                                border-top: 1px solid rgba(0, 0, 0, 0.1);
                                background-color: rgba(0, 0, 0, 0.1);
                                margin-top: 5px;
                                padding: 5px 0;
                                justify-content: space-around;
                            }
                        }
                    }
                }
            }
        }
        // .control {
        //     border: 1px solid black;
        //     padding: 1px 6px;
        //    // display: flex;
        //     background: #cdf;
        //     .layout-title {
        //         align-self: center;
        //         font-size: 110%;
        //         margin-right: 8px;
        //         display: inline;
        //         width: 100px;
        //     }
        //     .number-options {
        //         background-color: rgba(0, 0, 0, 0.04);
        //         padding: 2px 2px 2px 5px;
        //         border-left: 1px solid rgba(0,0,0,0.1);
        //         width: 164px;
        //         text-align: right;
        //         //display: block;        // .control {
        //     border: 1px solid black;
        //     padding: 1px 6px;
        //    // display: flex;
        //     background: #cdf;
        //     .layout-title {
        //         align-self: center;
        //         font-size: 110%;
        //         margin-right: 8px;
        //         display: inline;
        //         width: 100px;
        //     }
        //     .number-options {
        //         background-color: rgba(0, 0, 0, 0.04);
        //         padding: 2px 2px 2px 5px;
        //         border-left: 1px solid rgba(0,0,0,0.1);
        //         width: 164px;
        //         text-align: right;
        //         //display: block;
        //         white-space: nowrap;
        //         select {
        //             min-width: 40px;
        //             margin-left: 5px;
        //         }
        //     }
        //     .countersize-options{
        //         input[type="text"] {
        //             width: 50px;
        //             margin-left: 5px;
        //         }
        //     }
        // }
        //             margin-left: 5px;
        //         }
        //     }
        //     .countersize-options{
        //         input[type="text"] {
        //             width: 50px;
        //             margin-left: 5px;
        //         }
        //     }
        // }
    }
}

/*
        <div className="sheet-control">
            <div>counter sheet</div>
            <div className="controls">

                <div>counters layout</div>
                
                <div classNamme="number-options">
                    <span>number across:</span>
                    <select className=""
                        name="sheetControlNumberAcrossSelect"
                        id="sheetControlNumberAcrossSelect"
                        value={numberAcross}
                        onChange={onChangeNumberAcross}>
                        <option value="">-</option>
                        {Array(numberAcrossAvailable).fill().map((_, i) => {
                            return <option key={i} value={i}>{i}</option>
                        })}
                    </select>
                </div>
                
                <div classNamme="number-options">
                    <span>number down:</span>
                    <select className=""
                        name="sheetControlNumberDownSelect"
                        id="sheetControlNumberDownSelect"
                        value={numberDown}
                        onChange={onChangeNumberDown}>
                        <option value="">-</option>
                        {Array(numberDownAvailable).fill().map((_, i) => {
                            return <option key={i} value={i}>{i}</option>
                        })}
                    </select>
                </div>
            </div>
        </div>
        */
