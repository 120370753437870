.counter {
    position: relative;
    height: 100%;
    pointer-events: none;
    // min-width: 700px;
    // min-height: 700px;
    //background: blue;
    transform: perspective(155px), scale(0.5);
    .draw-layer-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition-duration: 100ms;
    }
    .out-of-bounds-indicator {
        position: relative;
        width: 100%;
        height: 100%;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        border: 0;
        margin: 0;
        &.circle {
            background: -webkit-radial-gradient(50% 49.3%, circle, transparent 0%, transparent 57.9%, #000 58%);
        }
    }
    .out-of-bounds-indicator.square:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
        height: 100%;
        background-color: #000;
        mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M0 0  240 0 240 20 0 20 z'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M220 20  240 20  240 220  220 220 z'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M0 220  240 220 240 240 0 240 z'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M0 20  20 20  20 220  0 220 z'/%3E%3C/svg%3E");
    }
    .out-of-bounds-indicator.rectangle:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
        height: 100%;
        background-color: #000;
        mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M0 0  240 0 240 70 0 70 z'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M220 20  240 20  240 220  220 220 z'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M0 170  240 170 240 240 0 240 z'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' " + "viewBox='0 0 240 240'%3E%3Cpath d='M0 20  20 20  20 220  0 220 z'/%3E%3C/svg%3E");
    }

    .xy-display {
        z-index: 2;
        position: absolute;
        bottom: 7px;
        right: 10px;
        font-size: 110%;
        color: #ccc;
        div > span:nth-child(2) {
            display: inline-block;
            width: 30px;
            text-align: right;
        }
        div:nth-child(1) {
            color: #0aa;
        }
        div:nth-child(2) {
            color: #f66;
        }
        @media (max-width: 1060px) {
            transform: scale(0.8);
            bottom: 5px;
        }
    }
}
