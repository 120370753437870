.input-color {
    display: flex;
    padding: 3px 2px 3px 2px;
    justify-content: center;
    .named {
        font-size: 110%;
        margin-top: 3px;
        margin-right: 3px;
    }
    input[type="text"] {
        width: 100px;
        font-size: 120%;
        padding: 1px 1px 0 1px;
        margin: 0 5px 0 0;
        &.input-color-pick {
            background: #e0f0ff;
            &.invalid {
                background: #ffaaaa;
            }
        }
    }
    .color-sample {
        width: 26px;
        margin-right: 7px;
        border: 1px solid white;
        box-sizing: border-box;
        cursor: pointer;
    }
    .action-button {
        //font-size: 100%;
        padding-top: 2px;
        text-shadow: 0 0 black, 1px 1px rgba(255, 255, 255, 0.5), -1px -1px rgba(255, 255, 255, 0.5), 
        2px 2px rgba(255, 255, 255, 0.5), -2px -2px rgba(255, 255, 255, 0.5),
        1px -1px rgba(255, 255, 255, 0.5), 2px -2px rgba(255, 255, 255, 0.5),
        0 2px rgba(255, 255, 255, 0.5), 1px 2px rgba(255, 255, 255, 0.5);
        &.multicolor {
            width: 13px;
            border-radius: 50%;
            background: radial-gradient(
                
                rgba(255, 0, 0, 0.8) 0%,
                rgba(255, 154, 0, 0.8) 10%,
                rgba(208, 222, 33, 0.8) 20%,
                rgba(79, 220, 74, 0.8) 30%,
                rgba(63, 218, 216, 0.8) 40%,
                rgba(47, 201, 226, 0.8) 50%,
                rgba(118, 167, 255, 0.8) 60%,
                rgba(185, 111, 255, 0.8) 70%,
                rgba(211, 62, 255, 0.8) 80%,
                rgba(255, 97, 247, 0.8) 90%,
                rgba(255, 0, 0, 0.8) 100%
            );
        }
        &:hover {
            color: black;
        }
        &.invalid {
            background: #aaa;
            cursor: default;
            text-shadow: 0 0 black, 1px 1px rgba(255, 255, 255, 0);
        }
    }
    .multicolor-button {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
    }
}
