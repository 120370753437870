.help {
    height: calc(100vh - 60px);
    margin: 5px 0 10px 0;
    top: 22px;
    padding: 0 10px 20px 10px;
    border-radius: 5px;
    background: #ddd;
    border: 1px solid black;
    overflow-y: auto;
    overflow-x: none;
    font-size: 110%;
    p {
        line-height: 120%;
      }
    .help-top {
        .help-title {
            font-size: 120%;
            text-shadow: 0 0 black;
            span {
                color: red;
                font-size: 80%;
                margin-left: 10px;
            }
        }
        .close-button {
            height: 28px;
        }
        margin-top: 14px;
        height: 28px;
        display: flex;
        justify-content: space-between;
    }
    .disclaimer {
        background: #fdd;
        padding: 0 16px;
        border: 1px solid #e55;
    }
    .clear-both-pad-top {
        clear: both;
        padding-top: 24px;
    }
    .float-left {
        float: left;
        margin: 0 10px;
    }
    .float-left::after {
        content: "";
        clear: both;
      }
      .note {
        color: #e00;
        text-shadow: 0 0 black;
      }
}