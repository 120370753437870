.action-button {
  display: inline;
  padding: 0.04rem 0.3rem 0.2rem 0.3rem;
  user-select: none;
  &.windows {
    padding: 0.05rem 0.2rem 0.124rem 0.2rem;
  }
  text-shadow: 0 0 rgba(0, 0, 0, 0.5);
  background-color: #7bb;
  color: black;
  border-left: 1px solid rgba(200, 200, 255, 0.1);
  border-top: 1px solid rgba(200, 200, 255, 0.1);
  border-right: 1px solid rgba(115, 155, 155, 0.05);
  border-bottom: 1px solid rgba(200, 200, 255, 0.05);
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
    inset 1px 1px 1px 1px rgba(255, 255, 255, 0.3), inset -1px -1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  letter-spacing: 0.04rem;
  &.cyanish {
    text-shadow: 0 0 rgba(0, 0, 0, 0.5);
    background-color: #7cc;
    color: black;
    border-left: 1px solid rgba(200, 200, 255, 0.1);
    border-top: 1px solid rgba(200, 200, 255, 0.1);
    border-right: 1px solid rgba(115, 155, 155, 0.05);
    border-bottom: 1px solid rgba(200, 200, 255, 0.05);
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
      inset 1px 1px 1px 1px rgba(255, 255, 255, 0.3), inset -1px -1px 1px 1px rgba(0, 0, 0, 0.5);
    &:hover {
      color: #00f;
    }
    &:active {
      color: #820;
      text-shadow: 0 1px rgba(220, 120, 0, 0.6);
      background-color: #8cc;
      border-left: 1px solid rgba(0, 0, 255, 0.1);
      border-top: 1px solid rgba(0, 0, 55, 0.1);
      border-right: 1px solid rgba(5, 155, 155, 0.05);
      border-bottom: 1px solid rgba(200, 200, 255, 0.05);
      box-shadow: 0 0 1px 1px rgba(55, 55, 155, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
        inset 1px 1px 1px 1px rgba(255, 255, 255, 0.3), inset -1px -1px 1px 1px rgba(5, 0, 10, 0.5);
    }
  }

  &.orangish {
    text-shadow: 0 0 rgba(0, 0, 0, 0.5);
    background-color: rgb(251, 168, 3);
    color: black;
    border-left: 1px solid rgba(250, 220, 205, 0.1);
    border-top: 1px solid rgba(250, 220, 205, 0.1);
    border-right: 1px solid rgba(115, 155, 155, 0.05);
    border-bottom: 1px solid rgba(250, 220, 205, 0.05);
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
      inset 1px 1px 1px 1px rgba(255, 255, 255, 0.3), inset -1px -1px 1px 1px rgba(0, 0, 0, 0.5);
    &:hover {
      color: #f00;
      text-shadow: 0 0 rgba(0, 0, 0, 0.9);
    }
    &:active {
      text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
      background-color: rgb(255, 190, 30);
      color: red;
      border-left: 1px solid rgba(50, 20, 5, 0.4);
      border-top: 1px solid rgba(50, 20, 5, 0.4);
      border-right: 1px solid rgba(250, 200, 205, 0.1);
      border-bottom: 1px solid rgba(250, 220, 205, 0.1);
      box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
        inset 1px 1px 1px 1px rgba(255, 255, 255, 0.3), inset -1px -1px 1px 1px rgba(0, 0, 0, 0.5);
    }
  }

  &.blueish {
    padding: 0.1rem 0.3rem 0.22rem 0.3rem;
    border-left: 1px solid rgba(66, 66, 255, 1);
    border-top: 1px solid rgba(66, 66, 255, 1);
    border-right: 1px solid rgba(0, 0, 55, 1);

    border-bottom: 1px solid rgba(0, 0, 155, 1);
    background-color: #adf;
    &:hover {
      background-color: #bef;
      color: red;
    }
    &:active {
      color: blue;
      border-bottom: 1px solid #aaa;
    }
  }

  &.yellowish {
    padding: 0.1rem 0.3rem 0.22rem 0.3rem;
    border-left: 1px solid rgba(200, 200, 55, 1);
    border-top: 1px solid rgba(200, 200, 55, 1);
    border-right: 1px solid rgba(200, 200, 55, 1);
    border-bottom: 1px solid rgba(200, 200, 55, 1);
    background-color: #f6f690;
    &:hover {
      background-color: #ffa;
    }
  }

  &.reddish {
    padding: 0.1rem 0.3rem 0.22rem 0.3rem;
    border-left: 1px solid rgba(255, 50, 50, 1);
    border-top: 1px solid rgba(255, 50, 50, 1);
    border-right: 1px solid rgba(255, 50, 50, 1);
    border-bottom: 1px solid rgba(255, 50, 50, 1);
    background-color: rgb(233, 68, 8);
    color: white;
    text-shadow: 1px 1px rgba(0,0,0,0.5);
    &:hover {
      background-color: rgb(237, 95, 43);
    }
  }

  &:hover {
    cursor: pointer;
  }
  &.show {
    display: inline-block;
  }
  &.disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
    padding: 0.08rem 0.3rem 0.26rem 0.3rem;
  }
  &:active {
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
      inset 0 0 2px 2px rgba(0, 0, 0, 0.4), inset 1px 1px 13px 13px rgba(0, 0, 0, 0.1),
      inset -1px -1px 1px 1px rgba(0, 0, 0, 0.4);
    &.disabled {
      color: #888;
      text-shadow: 0 1px #888;
      background-color: #ccc;
      border-left: 1px solid rgba(0, 0, 255, 0.1);
      border-top: 1px solid rgba(0, 0, 55, 0.1);
      border-right: 1px solid rgba(5, 155, 155, 0.05);
      border-bottom: 1px solid rgba(200, 200, 255, 0.05);
      box-shadow: 0 0 0 0 rgba(55, 55, 155, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
        inset 0 0 0 0 rgba(255, 255, 255, 0.5), inset -1px -1px 1px 1px rgba(5, 0, 10, 0.5);
    }
  }
  &.red {
    padding: 0.2rem 0.3rem 0.22rem 0.3rem;
    background-color: #f44;
    color: white;
    text-shadow: 0 0 white;
    border-left: 1px solid rgba(255, 50, 50, 0.1);
    border-top: 1px solid rgba(255, 50, 50, 0.1);
    border-right: 1px solid rgba(215, 55, 55, 0.05);
    border-bottom: 1px solid rgba(200, 55, 55, 0.05);
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.5), 1px 1px 1px 2px rgba(0, 0, 0, 0.5),
      inset 1px 1px 1px 1px rgba(255, 255, 255, 0.3), inset -1px -1px 1px 1px rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: #e44;
    }
  }
}

/* The switch - the box around the slider */
.slider-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 30px; //60px;
    height: 17px; //34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px; //26px;
    width: 13px; //26px;
    left: 2px; //4px;
    bottom: 2px; //4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #00aaaa;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #00aaaa;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
