.error-message {
    background: #E00;
    color: black;
    position: absolute;
    z-index: 3;
    left: 50%;
    top: -200px;
    //margin: 0 20px 0 20px;
    border: 2px solid black;
    overflow-y: hidden;
    overflow-x: hidden;
    box-shadow: 0 0 20px 10px rgba(0,0,0,0.4);
    max-height: 500px;
    font-size: 130%;
    animation: 100ms linear 0s 1 forwards slide-down-error;

    >div:nth-child(1) {
        background: red;
        color: white;
        padding: 1px 0 1px 0;
        text-align: center;
        margin-bottom: 5px;
    }
    >div:nth-child(2) {
        position: absolute;
        top: 1px;
        right: 5px;
        transform: scaleX(1.3);
        width: 16px;
        height: 20px;
        text-align: center;
        padding: 0 0 2px 0;
        border: 1px solid #aaa;
        background-color: #fff;
        cursor: pointer;
        &:hover {
            background-color: #aaa;
        }
    }
     .message {
        padding: 1rem 2rem 0rem 2rem;
        background: white;
        &:last-child {
            padding: 1rem 2rem 1rem 2rem;
        }
    }
    @keyframes slide-down-error {
        0% { top: -200px; transform: translateX(-50%);}
        50% { height: auto; transform: translateX(-50%);}
      100% { top: 100px; height: auto; transform: translateX(-50%);}
  }
}



