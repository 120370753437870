.tabs {
    padding-top: 7px;
    .tab-labels {
        display: flex;
        .tab-title {
            border: 1px solid rgba(0,255,255,0.1);
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            background-color: #377;
            color: rgba(0, 0, 0, 0.7);
            padding: 0 5px;
            font-size: 60%;
            border-collapse: collapse;
            margin-right: 3px;
            margin-left: 3px;
            transform: scale(0.9);
            cursor: pointer;
            &.selected {
                cursor: default;
                color: black;
                background-color: #2cc;
                border: 1px solid rgba(55,255,255,0.2);
                position: relative;
                top: 1px;
                transform: scale(1);
                text-shadow: 0 0 #0ff;
                &:hover {
                    color: black;
                }
            }
            &:hover {
                color: #afa;
            }
        }
    }
    .tab-shadow {
        height: 5px;
        background: linear-gradient(#00ffff, #000000);
    }
    .tab-contents {
        background-color: black;
    }
}
