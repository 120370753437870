.counter-menu-layer-option {
    &.faded {
        opacity: 0.5;
    }
    .command-line {
        border-top: 1px solid black;
        font-size: 50%;
        display: flex;
        justify-content: space-between;
        position: relative;
        //border: 1px solid rgba(255, 255, 255, 0.25);
        //border: 1px solid red;
        border-bottom: 1px solid #333;
        &.menu-opened {
            box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.3), 0 1px 0 0 rgba(255, 255, 255, 0.3);
            background: #016;
            color: white;
            text-shadow: 0 0 rgba(0, 0, 210, 0.5);
            border-bottom: 1px solid black;
        }
        .activate-button {
            width: 16px;
            height: 16px;
            position: relative;
            top: 0.25rem;
            left: 2px;
            border-radius: 100%;
            background-color: #555;
            cursor: pointer;
            &.active {
                background: radial-gradient(circle at center, #7ff 20%, #0ff 50%, #0cc 60%);
                box-shadow: 0 0 2px 1px rgba(100, 255, 255, 0.4);
                &.not-allowed {
                    cursor: not-allowed;
                }
            }
            &.not-allowed {
                cursor: not-allowed;
            }
            &:hover {
                background: radial-gradient(circle at center, #ff7 20%, #ee0 50%, #cc0 60%);
                &.active {
                    background: radial-gradient(circle at center, #cee 20%, #aee 50%, #799 60%);
                }
                &.not-allowed {
                    background: #555;
                }
            }
        }
        .option-name {
            display: flex;
            white-space: nowrap;
            justify-content: space-between;
            width: 100%;
            color: #777;
            text-align: left;
            
            &.currently-active {
                // box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.3), 0 1px 0 0 rgba(255, 255, 255, 0.3);
                // background: #016;
                // color: white;
                // text-shadow: 0 0 rgba(0, 0, 210, 0.5);
                // border-bottom: 1px solid black;
            }
            .layer-active {
                color: white;
                text-shadow: 0 0 rgba(0, 0, 210, 0.5);
            }
            .hilite {
                background: white;
                color: black;
            }
            div {
                padding: 2px 5px 2px 5px;
                line-height: 130%;
                width: 100%;
                &:hover {
                    cursor: pointer;
                    color: white;
                    // background: #111;
                }

                &:first-child {
                    flex-grow: 2;
                }
                &:nth-child(2) {
                    flex: 1;
                    font-size: 150%;
                    line-height: 50%;
                    position: relative;
                    top: 1px;
                    cursor: pointer;
                    &:hover {
                        color: cyan;
                    }
                    &.active {
                        position: relative;
                        z-index: 11;
                    }
                }
            }
        }
        .option-menu {
            //     height: 17px;
            //     width: 15px;
            //     padding-top: 3px;
            //    // margin-top: 0.1rem;
            //     cursor: pointer;
            //     &.active {
            //         position: relative;
            //         z-index: 100;
            //     }
            //     &:hover {
            //         background: radial-gradient(#e66465, #ffffff);
            //     }
            //     &.layer-active {
            //         background: #06f;
            //         border-bottom: 1px solid black;
            //     }
        }
    }
}
