.input-font {
    display: flex;
    justify-content: center;
    line-height: 17px;
    .named {
        font-size: 110%;
        margin-right: 5px;
        position: relative;
        top: 2px;
    }
    select {
        font-size: 110%;
    }
}