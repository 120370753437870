.data-display {
    //margin-bottom: 400px;
    height: calc(100vh - 40px);
    .there-are-none {
        font-size: 150%;
        color: white;
        padding: 10px 50px;
        border-radius: 3%;
    }
    .data-display-top {
        .display-title {
            color: white;
            font-size: 110%;
        }
        .close-button {
            height: 28px;
        }
        margin-top: 5px;
        padding: 2px 2px 5px 2px;
        height: 28px;
        display: flex;
        justify-content: space-between;
    }
    margin-top: 5px;
    top: 22px;
    padding: 0 10px 10px 10px;
    border-radius: 5px;
    background: rgba(40, 50, 122, 0.8);
    border: 1px solid black;
    overflow-y: auto;
    overflow-x: none;
    .data-row-values {
        display: flex;
    }

    .data-container {
        table.saved-counters {
            display: grid;
            border-collapse: collapse;
            border: 1px solid #aaa;
            max-width: 99%;
            grid-template-columns:
                minmax(90px, 1fr)
                minmax(100px, 0.7fr)
                minmax(80px, 0.9fr)
                minmax(90px, 2fr)
                minmax(90px, 0.5fr)
                minmax(140px, 1.67fr);
            td.row-grabber {
                grid-column: 1/7;
            }
        }
    }

    .data-container {
        table.saved-sheets {
            display: grid;
            border-collapse: collapse;
            border: 1px solid #f00;
            max-width: 99%;
            grid-template-columns:
                minmax(150px, 500px)
                minmax(100px, 145px)
                minmax(80px, 0.9fr)
                minmax(200px, 1fr)
                minmax(90px, 180px)
                minmax(90px, 180px)
                minmax(80px, auto);
            td.row-grabber {
                grid-column: 1/8;
            }
        }
    }

    .data-container {
        table.installed-svgs {
            display: grid;
            border-collapse: collapse;
            border: 1px solid #aaa;
            max-width: 99%;
            grid-template-columns:
                minmax(180px, 1fr)
                minmax(70px, 0.3fr)
                minmax(280px, 2.9fr)
                minmax(90px, 1fr)
                minmax(90px, 1fr);
            td.row-grabber {
                grid-column: 1/6;
            }
            td[class*="td-adjust"] {
                height: 455px;
            }
            td {
                .rename-installed-svg {
                    border: 1px solid rgb(169, 169, 247);
                    background: rgba(190, 190, 255, 0.2);
                    margin-top: 10px;
                    padding: 5px 5px 9px 5px;
                    text-align: left;
                    input[type="text"] {
                        margin-top: 3px;
                        width: calc(100% - 15px);
                    }
                    div {
                        text-align: center;
                        .action-button {
                            margin: 8px auto 0 auto;
                        }
                    }
                    .already-taken {
                        padding-top: 10px;
                        color: red;
                    }
                    .name-updated {
                        padding-top: 10px;
                        color: green;
                    }
                }
            }
        }
        table.installed-images {
            display: grid;
            border-collapse: collapse;
            border: 1px solid #aaa;
            max-width: 99%;
            grid-template-columns:
                minmax(90px, 1fr)
                minmax(100px, 0.6fr)
                minmax(280px, 2.9fr)
                minmax(90px, 1fr)
                minmax(90px, 1fr);
            td.row-grabber {
                grid-column: 1/6;
            }
            td[class*="td-adjust"] {
                height: 455px;
            }
            td {
                .rename-installed-svg {
                    border: 1px solid rgb(169, 169, 247);
                    background: rgba(190, 190, 255, 0.2);
                    margin-top: 10px;
                    padding: 5px 5px 9px 5px;
                    text-align: left;
                    input[type="text"] {
                        margin-top: 3px;
                        width: calc(100% - 15px);
                    }
                    div {
                        text-align: center;
                        .action-button {
                            margin: 8px auto 0 auto;
                        }
                    }
                    .already-taken {
                        padding-top: 10px;
                        color: red;
                    }
                    .name-updated {
                        padding-top: 10px;
                        color: green;
                    }
                }
            }
        }
    }

    .data-container {
        user-select: text;
        table.fonts {
            display: grid;
            border-collapse: collapse;
            border: 1px solid #aaa;
            max-width: 99%;
            grid-template-columns:
                minmax(250px, 4fr)
                minmax(200px, 6fr);
            td[class*="td-adjust"] {
                height: 232px;
            }
            td.rendered-column {
                .controls {
                    background: #acf;
                    border: 1px solid #35a;
                    width: 90%;
                    padding: 10px;
                    margin: 10px auto 10px;
                    .controls-container {
                        > div:first-child {
                            padding-bottom: 5px;
                        }
                        text-align: left;
                        font-size: 1vw;
                        input[type="text"] {
                            width: calc(100% - 10px);
                            font-size: 150%;
                        }
                    }
                    .control-inputs {
                        margin-top: 5px;
                        font-size: 120%;
                        label {
                            cursor: pointer;
                        }
                        input[type="checkbox"] {
                            cursor: pointer;
                            margin-right: 3px;
                            margin-left: 10px;
                        }
                        input[type="range"] {
                            position: relative;
                            top: 3px;
                            margin-left: 10px;
                            width: 16vw;
                        }
                    }
                }
            }
            td {
                .key-value-flex {
                    display: flex;
                    font-size: 110%;
                    align-items: flex-end;
                    div {
                        color: #00e;
                        padding-top: 10px;
                    }
                    div:first-child {
                        color: black;
                        width: 140px;
                        min-width: 140px;
                        text-align: right;
                        margin-right: 10px;
                    }
                    div:nth-child(2) {
                        display: flex;
                        white-space: nowrap;
                        align-items: flex-end;
                        span {
                            margin-left: 10%;
                        }
                    }
                }
            }
            td.row-grabber {
                grid-column: 1/3;
            }
        }
    }

    .data-container {
        text-align: center;
        display: flex;
        justify-content: center;

        thead,
        tbody,
        tr {
            display: contents;
        }

        td.row-grabber {
            // border-bottom: 10px solid red;
            color: black;
            background: red;
            height: 18px;
            text-align: center;
            overflow: hidden;
            i {
                font-size: 140%;
                transform: scaleX(180%);
                padding-top: 1px;
                cursor: pointer;
                &:hover,
                &:active {
                    color: red;
                    border: 1px solid black;
                    background: yellow;
                    padding-top: 1px;
                    position: relative;
                    top: -0.05rem;
                }
            }
            //    position: relative;
            //    left: 0;
        }

        th,
        td {
            padding: 5px;
            overflow-x: hidden;
            overflow-y: auto;
            word-break: break-all;
            // text-overflow: ellipsis;
            text-align: left;
            text-align: left;
            font-weight: normal;
            font-size: 90%;
            letter-spacing: 0.05rem;
            color: white;
            border-left: 1px solid rgba(255, 255, 255, 0.6);
            &:first-child {
                border-left: 1px solid transparent;
            }
        }
        th {
            background: black;
            text-align: left;
            white-space: nowrap;
            font-size: 90%;
            letter-spacing: 0.05rem;
            color: white;
            border-left: 1px solid rgba(255, 255, 255, 0.6);
            &:first-child {
                border-left: 1px solid transparent;
            }
        }

        td {
            padding-top: 2px;
            padding-bottom: 2px;
            color: #000;
            font-size: 80%;
            border-left: 1px solid rgba(0, 0, 0, 0.3);
            border-top: 1px solid #555;
            height: 380px; // 280
            overflow-y: auto;
            &:first-child {
                border-left: 1px solid transparent;
            }
            pre {
                font-size: 80%;
                white-space: pre-wrap;
            }
            .key-object {
                color: #00e;
                label:hover {
                    cursor: pointer;
                }
            }
            .key-string {
                color: black;
                label:hover {
                    cursor: pointer;
                }
                span {
                    color: green;
                    cursor: default;
                }
            }
            .key-number {
                color: black;
                label:hover {
                    cursor: pointer;
                }
                span {
                    color: red;
                    cursor: default;
                }
            }
            .indented {
                padding-left: 40px;
                .key-object {
                    label:hover {
                        cursor: default;
                    }
                }
                .key-string {
                    label:hover {
                        cursor: default;
                    }
                }
                .key-number {
                    label:hover {
                        cursor: default;
                    }
                }
            }
            &.rendered-column {
                .svg-container {
                    width: 100%;
                    min-width: 180px;
                    &.counters {
                        padding-top: 10%;
                        aspect-ratio: 1/1;
                    }
                    &.sheets {
                        width: 100%;
                        aspect-ratio: 1200/1553;
                    }
                    background-image: repeating-linear-gradient(45deg, #aaa, #bbb 4px, #ccc 4px, #aaa 8px);
                    background-size: 100px 100px;
                }
                text-align: center;
                .buttons {
                    font-size: 130%;
                    display: inline-block;
                    text-align: center;
                    button {
                        text-align: center;
                        margin: 5px;
                        padding: 1px 10px 3px 10px;
                    }
                }
            }
        }

        tr td {
            background: #d8eeea;
        }
        tr:nth-child(even) td {
            background: #ddd;
        }
    }
}
