.input-width-height {
    font-size: 110%;
    .named {
        font-size: 100%;
        text-align: left;
        margin-left: 5px;
        margin-bottom: 4px;
        cursor: pointer;
    }
    .width-height-control {
        .dimension {
            position: relative;
            top: -2px;
        }
        table {
            padding: 0;
            margin: 0;
            td {
                padding: 0;
                margin: 0;
                &.slider {
                    input[type="range"] {
                        border: 1px solid white;
                        width: 100%;
                        background-color: #9a905d;
                    }
                    &.locked {
                        &.width {
                            border-top: 1px solid rgba(255, 255, 255, 0.3);
                            border-right: 1px solid rgba(255, 255, 255, 0.3);
                            border-left: 1px solid rgba(255, 255, 255, 0.3);
                            position: relative;
                            top: 1px;
                            left: 2px;
                        }
                        &.height {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                            border-right: 1px solid rgba(255, 255, 255, 0.3);
                            border-left: 1px solid rgba(255, 255, 255, 0.3);
                            position: relative;
                            top: -1px;
                            left: 2px;
                        }
                    }
                }
                input[type="text"] {
                    width: 40px;
                    &.percent {
                        width: 37px;
                        position: relative;
                        right: 5px;
                        &.windows-chrome {
                            width: 40px;
                            right: 3px;
                        }
                    }
                    &.warning {
                        background-color: #faa;
                    }
                }
            }

            td.aspect-ratio {
                width: 25px;
                cursor: pointer;
                &.inactive {
                    filter: blur(0.01rem);
                    opacity: 0.7;
                }
            }
            width: 100%;
        }
    }
    .coordinate-container {
        display: flex;
        justify-content: center;
        span {
            margin-right: 5px;
            position: relative;
            top: -2px;
            cursor: pointer;
        }
        .slider {
            width: 250px;
        }
        .coordinate-readout {
            font-size: 90%;
            width: 25px;
            margin-top: 1px;
            margin-left: 5px;
            text-align: right;
            color: #ccf;
        }
        .proportional-locker {
            width: 12px;
            height: 12px;
            white-space: nowrap;
            position: relative;
            margin-left: 3px;
            .top-l {
                position: relative;
                top: 2px;
                display: block;
                transform: scale(-1, -1);
            }
            .bottom-l {
                position: relative;
                top: -2px;
                display: block;
                transform: scaleX(-1);
            }

            span.proportional-control {
                //display: none;
                z-index: 1;
                position: absolute;
                top: 8px;
                left: -1px;
            }
        }
    }
}
