@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
         transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.spinner {
    // The height here is just for demo purposes
    height: 80vh;
    opacity: 1;
    position: relative;
    transition: opacity linear 0.1s;    
    &::before {
        animation: 1s linear infinite spinner;
        border: solid 3px #eee;
        border-bottom-color: #EF6565;
        border-radius: 50%;
        content: "";
        height: 40px;
        left: 50%;
        opacity: inherit;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center;
        width: 40px;
        will-change: transform;
    }
    .message {
        position: absolute;
        color: white;
        text-align: center;
        height: 40px;
        left: calc(50% - 200px);
        width: 400px;
        top: calc(50% + 40px);
        font-size: 125%;
    }
}