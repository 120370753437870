.material-symbol-button {
    //border: 1px solid red;
    //margin-top: 1px;
   // animation: fadeInFromNone 0.2s ease-out;
    .material-symbol-box {
        background: #000525;
        padding: 5px 5px 1px 5px;
        border: 1px solid #555;
        position: relative;
        cursor: pointer;
        color: rgb(200,200,255);
        &:hover {
            background: #228;
            border: 1px solid #aaa;
            position: relative;
            color: rgb(212,212,243);
        }
        &.selected {
            background: #02c;
            color: white;
            box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(0, 0, 255, 0.4);
            border: 1px solid white;
        }
        .tool-tip {
            pointer-events: none;
            position: absolute;
            top: 0px;
            left: 0px;
            background: #228;
            color: white;
            z-index: 1;
            border: 1px solid #666;
            padding: 2px 4px;
            animation: fadeInFromNone 0.1s ease-out;
        }
    }

    .material-symbol-name {
        font-size: 90%;
        position: relative;
        top: -5px;
        width: 60px;
        text-align: center;
        &:not(.selected) {
            color: #aaa;
        }
    }
}
