.x-button {
    fill: none;
    circle {
        stroke: #555;
        fill: #eee;
    }
    line {
        stroke: #555;
    }
    &:hover {
        fill: rgba(200,100,0,0.1);
        cursor: pointer;
        circle {
            stroke: #fff;
            fill: #c00;
        }
        line {
            stroke: #fff;
        }
    }
}