.install-image {
    display: inline-block;
    background: #eee;
    margin: 2px 3px;
    padding: 0px 20px 20px 20px;
    user-select: text;
    width: 700px;
    .install-image-top {
        margin-top: 5px;
        padding: 8px 2px 0 2px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        .title {
            font-size: clamp(18px, 1.8vw, 20px);
            font-weight: bold;
        }
        .close-button {
            height: 28px;
            position: relative;
        }
    }
    .intro {
        p {
            margin: 5px 10px 10px 10px;
        }
        font-size: clamp(17px, 1vw, 20px);
    }
    .install-contain {
        height: 222px;
        overflow: hidden;

        .install {
            position: relative;
            width: 690px;
            background-color: #e0efff;
            //margin: 20px;
            border: 1px solid gray;
            display: inline-flex;
            align-items: center;
            box-sizing: border-box;  
            height: 220px;
            .text-area {
                height: 90%;
                margin-right: 1%;
                //width: 60%;
                //margin-top: 10%;
                //padding: 20px 10px;
                
                .success {
                    padding-bottom: 10px;
                }
                .input-items {
                    margin-top: 5px;
                    input[type="text"] {
                        width: 200px;
                        margin-right: 5px;
                        &.invalid {
                            background: #FFdddd;
                        }
                    }
                }
                .name-taken {
                    color: red;
                    margin-top: 5px;
                }
                .actions {
                    position: absolute;
                    bottom: 10px;
                    padding: 20px;
                    display: flex;
                    button {
                        margin: 0 10px;
                    }
                }
            }

            // padding: 10px;
            .image-container {
                background: white;
                display: flex;
                align-items: center;
                height: 100%;
                margin: 10px;
                width: 210px;
                height: 210px;
                border: 1px solid black;
                text-align: center;
                &.imageLoadError {
                    filter: sepia(100%) saturate(100%) brightness(80%) hue-rotate(280deg);
                }
                

                .background-for-image {
                    width: 200px;
                    margin: auto auto;
                    position: relative;
                    background: white;
                    .import-image {
                        width: 200px;
                        height: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .input-image-select {
                justify-content: center;
                align-self: center;
                margin: 0 20px;
                .already-used {
                    padding: 10px 10px 5px 10px;
                    margin-bottom: 13px;
                    border: 1px solid #b77;
                    background-color: #fcc;
                    span {
                        color: #01a;
                        text-shadow: 0 0 #03f;
                        font-size: 110%;
                    }
                }
                div {
                    margin-top: 18%;
                    padding: 0 0 5px;
                    white-space: nowrap;
                }
                input[type="file"] {
                    margin-top: 10px;
                }
            }
        }
    }
}
