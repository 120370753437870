.top-menu {
    border-top: 1px solid #ddd;
    background: #f5f5f5;
    border-bottom: 1px solid #888;
    border-left: 2px solid #eee;
    margin: 1px 5px 0 5px;
    border-radius: 4px;
    padding-bottom: 1px;
    display: flex;
    position: relative;
    z-index: 1;
    .drop-down-div {
        position: relative;
        padding: 0 7px 0 10px;
        //cursor: pointer;
        &:hover {
            color: #458;
            text-decoration: underline;
            text-shadow: 0 0 #458;
        }
    }
    .app-name-container {
        margin-left: auto;
        margin-right: 12px;
        span {
            font-style: italic;
            color: #33f;
            font-weight: bold;
            text-decoration: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
