.counter-menu-layer-options-menu {
    background-image: linear-gradient(#004, #003, #000);
    display: none;
    font-size: 50%;
    border-bottom: 1px solid #777;

    &.menu-opened {
        display: block;
    }
    .options-menu {
        padding: 5px 5px 4px 5px;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        animation: fadeInFromNone 0.1s ease-out;
        // .submit {
        //     text-align: right;
        //     padding: 5px 15px 14px 0;
        //     .action-button {
        //         padding-bottom: 0.12rem;
        //     }
        // }
        .spacer {
            margin-top: 5px;
            height: 5px;
            border-top: 1px dotted rgba(255, 255, 255, 0.3);
            margin: 8px 3px 4px 2px;
            //background: white;
        }
        .spacer3 {
            margin-top: 3px;
            height: 3px;
            border-top: 1px solid rgba(255,255,255,0.1);
        }
        .label {
            font-size: 16px;
            color: #b7baff;
        }
        > div:first-child {
            padding-top: 11px;
        }
        &.combat-movement {
            .input-text {
                margin: 0 0 10px 10px;
                &:nth-child(-n + 2) {
                    display: inline-block;
                    font-size: 110%;
                    input[type="text"] {
                        width: 20px;
                    }
                }
            }
            :nth-child(7){
                margin: 3px 0 0 0;
            }
            :nth-child(9){
                margin: 6px 0 0 0;
            }
        }
    }
}