.counter-menu-layer-common-options {
    position: absolute;
    top: 26px;
    left: 0;
    z-index: 10;
    width: calc(100% - 20px);
    font-size: 110%;
    background: #003;
    border: 1px solid white;
//    padding: 10px;
    .action-button.reddish {
        background: #c00;
        padding: 2px 10px 2px 10px;
        &:hover {
            color: yellow;
        }
    }
    .common-option {
        padding: 10px 0 6px 0;
        &:not(:first-child) {
            border-top: 1px solid #444;
            //margin-top: 10px;
           padding-bottom: 10px;
        }
        .info1 {
            cursor: pointer;
            span {
                color: cyan;
            }
            //padding: 3px;
        }
        .info2 {
            padding: 8px;
        }
        .info3 {
            font-size: 75%;
            color: #ccc;
        }
        .input-container {
            input[type="text"] {
                width: 90%;
                &.error {
                    background: #fbb;
                }
            }
            .layer-ordering {
                margin: 10px;
                padding: 0;
                border: 1px solid #777;
                background-color: #114;
                .choose-order {
                    text-align: center;
                    background: #036;
                    color: #0cc;
                    font-size: 80%;
                    border-bottom: 1px solid #777;
                    padding-top: 2px;
                    border-top: 1px solid #555;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    cursor: pointer;
                    &:hover {
                        background: #cc0;
                        color: #000;
                    }
                }
                .layer-order-row {
                    text-align: left;
                    
                    .layer-info {
                        &.current {
                            background: #03a;
                            color: yellow;
                        }
                    }
                    .order-number {
                        display: inline-block;
                        width: 120px;
                        text-align: right;
                        border-right: 1px solid #777;

                    }
                    .order-layer-name {
                        margin-left: 10px;
                       // max-width: 100px;
                    }

                    padding: 0;
                    //display: flex;
                    border-top: 1px solid #999;
                    // :last-child {
                    //     border-top: 11px solid red;
                    // }
                    .layer-info {
                    span:first-child {
                        padding: 1px 10px;
                        width: 20px;
                        text-align: right;
                    }
                    span:nth-child(2) {
                        font-size: 90%;
                        padding-top: 3px;
                    }
                }
                }
                &>:first-child {
                    background: #223;
                    color: #ccc;
                }
            }
        }
        .submit-div {
            padding: 12px 0 4px 0;
        }
    }
}
