.layers-options {
    font-size: 50%;
    padding: 1px 1px 5px 1px;
    margin-top: 10px;
    background-color: black;
    .layer-row {
        display: flex;
    }

    .grid-table {
        display: grid;
        padding: 10px;
        font-size: 120%;
        grid-template-columns: 50px auto;
        //grid-template-rows: repeat(auto-fill, 40%);
        //max-width: calc(100% - 20px);

        .grid-item {
            border: 1px solid rgba(200,200,255,0.3);
            background: #000033;
            padding-top: 2px;
            padding-bottom: 2px;
            input[type=checkbox]:disabled {
                cursor: not-allowed;
            }
            &:nth-child(2n) {
                text-align: left;
                border-left: 1px solid rgba(0,0,50,0.3);
                padding-left: 10px;
            }
            &.hidden {
               opacity: 0.5;
            }
            // padding: 4px 6px;
            // span {
            //     margin-right: 4px;
            // }
            // &.left-align {
            //     text-align: left;
            // }
            // &:not(:nth-child(3n)) {
            // border-right: 1px solid rgba(112,112,112,0.6);
            // }
            // border-bottom: 1px solid rgba(112,112,112,0.6);
      
            // color: white;
            &.header {
                background-color: #adf;
                color: black;
                margin-bottom: 2px;
                padding-top: 0;
                padding-bottom: 0;
            }
            border-bottom: 1px solid rgba(200,200,255,0.3);
            input[type="checkbox"] {
                cursor: pointer;
            }
        }
    }




}