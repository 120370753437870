.input-fill {
    font-size: 110%;
    .gradient-settings {
        margin-top: 10px;
        padding: 10px 0 10px 0;
        background-image: linear-gradient(#414, #043, #005);
        border: 1px solid rgba(255,255,255,0.3);
        >.title {
           font-size: 125%;
           position: relative;
           .closed {
            color: #aaa;
           }
           span {
            position: absolute;
            right: 10px;
            cursor: pointer;
            &:hover {
                color: red;
                background: yellow;
            }
           }
        }
    }
    .fillType-named {
        font-size: 100%;
        margin-bottom: 4px;
        cursor: pointer;
    }
    .radio-choice {
        .gradient-named {
            padding: 0px 0px 10px 0;
        }
        .fillType-radio-options {
            display: flex;
            justify-content: center;
            gap: 10px;
            label {
                margin-left: 6px;
                cursor: pointer;
            }
            .click-enabled {
                cursor: pointer;
                input[type="radio"] {
                    cursor: pointer;
                }
            }
        }
    }

    .radio-choice-gradient-direction {
        display: flex;
        justify-content: center;
        gap: 13px;
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid #555;
        .named {
            position: relative;
            top: -1px;
        }
        .radio-options {
            display: flex;
            justify-content: center;
            gap: 10px;
            label {
                margin-left: 6px;
                cursor: pointer;
            }
            .click-enabled {
                cursor: pointer;
                input[type="radio"] {
                    cursor: pointer;
                }
            }
        }
    }
    .input-xy-position_gradient {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid #555;
        .named {
            padding-bottom: 4px;
        }
        .coordinate-container {
            display: flex;
            justify-content: center;
            &.disabled {
                opacity: 0.5;
            }
            span {
                margin-right: 5px;
                position: relative;
                top: -2px;
                cursor: pointer;
            }
            .slider {
                width: 220px;
            }
            .coordinate-readout {
                width: 25px;
                margin-left: 5px;
                text-align: right;
                color: #ccf;
            }
        }
    }



    .input-color {
        display: flex;
        padding: 3px 20% 3px 2px;
        justify-content: right;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #555;
        &.solid {
            border-top: 1px solid rgba(0,0,0,0);
            padding-top: 3px;
        }
        .named {
            font-size: 100%;
            margin-top: 4px;
            margin-right: 3px;
        }
        input[type="text"] {
            width: 100px;
            font-size: 110%;
            padding: 1px 1px 0 1px;
            margin: 0 5px 0 0;
            &.input-color-pick {
                background: #e0f0ff;
                &.invalid {
                    background: #ffaaaa;
                }
            }
        }
        .color-sample {
            width: 26px;
            margin-right: 7px;
            border: 1px solid white;
            box-sizing: border-box;
            cursor: pointer;
        }
        .action-button {
            //font-size: 100%;
            padding-top: 2px;
            text-shadow: 0 0 black, 1px 1px rgba(255, 255, 255, 0.5), -1px -1px rgba(255, 255, 255, 0.5),
                2px 2px rgba(255, 255, 255, 0.5), -2px -2px rgba(255, 255, 255, 0.5), 1px -1px rgba(255, 255, 255, 0.5),
                2px -2px rgba(255, 255, 255, 0.5), 0 2px rgba(255, 255, 255, 0.5), 1px 2px rgba(255, 255, 255, 0.5);
            &.multicolor {
                width: 13px;
                border-radius: 50%;
                background: radial-gradient(
                    rgba(255, 0, 0, 0.8) 0%,
                    rgba(255, 154, 0, 0.8) 10%,
                    rgba(208, 222, 33, 0.8) 20%,
                    rgba(79, 220, 74, 0.8) 30%,
                    rgba(63, 218, 216, 0.8) 40%,
                    rgba(47, 201, 226, 0.8) 50%,
                    rgba(118, 167, 255, 0.8) 60%,
                    rgba(185, 111, 255, 0.8) 70%,
                    rgba(211, 62, 255, 0.8) 80%,
                    rgba(255, 97, 247, 0.8) 90%,
                    rgba(255, 0, 0, 0.8) 100%
                );
            }
            &:hover {
                color: black;
            }
            &.invalid {
                background: #aaa;
                cursor: default;
                text-shadow: 0 0 black, 1px 1px rgba(255, 255, 255, 0);
            }
        }
        .multicolor-button {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
        }
    }
}
