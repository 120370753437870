.sheet-operations {
    margin: 0 0 5px 0;
    position: relative;
    .saved-sheet-feedback {
        position: absolute;
        top: 0;
        left: 200px;
        color: #dfd;
        padding: 5px 10px 4px 10px;
        border: 1px solid rgb(8, 198, 36);
        white-space: nowrap;
        background: rgb(20, 122, 156);
        animation: fadein 0.3s;
        animation-fill-mode: forwards;
        animation: fadeInFromNone 0.1s ease-out;
        letter-spacing: 0.05rem;
    }
    .sheet-operations-opener {
        text-align: left;
        font-weight: bold;
        font-size: 120%;
        display: flex;
        cursor: pointer;
        &:hover {
            color: #05c;
        }
        .sheet-operations-control {
            font-size: 130%;
            margin-left: 5px;
            position: relative;
            top: -3px;
        }
    }
    .controls {
        margin-top: 3px;
        display: flex;
        .control {
            .column {
                padding: 2px 3px 5px 3px;
                &:first-child {
                    padding: 4px 3px 3px 0;
                }
                .option-title {
                    margin: 0 3px 0 5px;
                }
                .subscript {
                    margin-right: 6px;
                }
            }
            margin-right: 10px;
            &.sheet-load {
                width: 400px;
                select {
                    max-width: 240px;
                }
            }
            .control-title {
                background: rgb(170, 204, 204);
                background: linear-gradient(
                    0deg,
                    rgba(170, 224, 224, 1) 0%,
                    rgba(187, 255, 255, 1) 50%,
                    rgba(221, 255, 255, 1) 100%
                );
                display: inline-block;
                position: relative;
                margin-right: 100%;
                white-space: nowrap;
                border-top-right-radius: 10px;
                border-top: 1px solid gray;
                border-right: 1px solid gray;
                border-left: 1px solid gray;
                padding: 2px 0;
                position: relative;
                top: 1px;
                font-size: 90%;
                text-shadow: 0 0 rgba(0, 0, 0, 0.5);
                color: #002;
                letter-spacing: 0.02rem;
                span {
                    margin: 0 10px;
                }
            }

            .options {
                background-color: #cff;
                white-space: nowrap;
                border: 1px solid gray;
                padding: 1px;
                .load-section {
                    display: flex;
                    margin: 5px 0 10px 0;
                    &.app {
                        margin: 10px 0 10px 0;
                    }
                    .load-section-title {
                        padding: 2px 8px 2px 8px;
                    }
                    &.clear-sheet {
                        display: block;
                        text-align: right;
                        margin-top: 14px;
                        margin-right: 10px;
                    }
                }

                .save-radio-options {
                    text-align: left;
                    padding: 5px 5px 0px 0px;
                    input[type="radio"] {
                        margin: 0 5px 0 10px;
                        position: relative;
                        top: 1px;
                    }
                    &.guides2 {
                        padding: 5px 8px 5px 0px;
                    }
                }

                .save-sheet-input-container {
                    padding: 6px 6px 4px 6px;
                }

                .save-sheet-submit-container {
                    padding: 4px 0 7px 0;
                    .sheet-saved {
                        background-color: #c5f5f5;
                        color: #060;
                        height: 22px;
                        padding-top: 4px;
                    }
                }

                &.counter-size {
                    text-align: left;
                    select {
                        margin-left: 4px;
                        &.unused {
                            background: rgba(0, 0, 0, 0.2);
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }
                input[type="text"].mm {
                    width: 60px;
                    margin-right: 3px;
                    margin-top: 2px;
                    &.warning {
                        background: #fdd;
                    }
            
                }
                .option {
                    padding: 3px;
                    &.use-custom {
                        text-align: center;
                    }
                    &:first-child {
                        padding-top: 7px;
                    }
                    .option-title {
                        margin-right: 5px;
                    }
                    select {
                        min-width: 40px;
                    }

                    .subscript {
                        font-size: 90%;
                        position: relative;
                        top: 2px;
                    }
                    .warning {
                        background-color: rgba(255, 210, 210, 0.9);
                    }
                    .counter-custom-size {
                        width: 40px;
                        margin-right: 3px;
                        &.unused {
                            background: rgba(0, 0, 0, 0.2);
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }
                .two-by-two {
                    .row {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        width: 100%;
                    }
                    .center-column {
                        margin: auto;
                    }
                    .column {
                        display: flex;
                        flex-direction: column;
                        flex-basis: 100%;
                        flex: 1;
                        width: 140px;
                    }

                    // <div className="grid">

                    // <div className="row">
                    //     <div className='column'>
                    //         <span className="option-title">top:</span>
                    //         <input type="text" id="customWidth" className="mm" /><span className="subscript">mm</span>
                    //     </div>
                    //     <div className='column'>
                    //         <span className="option-title">bottom:</span>
                    //         <input type="text" id="customWidth" className="mm" /><span className="subscript">mm</span>
                    //     </div>
                    // </div>

                    .grid {
                        //display: flex;
                        .row {
                            display: flex;
                            padding: 0px;

                            > div:nth-child(2) {
                                align-items: flex-end;
                                margin-left: auto;
                            }
                            > div:nth-child(1) {
                                align-items: flex-start;
                            }

                            .column {
                                padding: 4px 5px;
                            }
                            &.reset-apply {
                                display: flex;
                                border-top: 1px solid rgba(0, 0, 0, 0.1);
                                background-color: rgba(0, 0, 0, 0.1);
                                margin-top: 5px;
                                padding: 5px 0;
                                justify-content: space-around;
                            }
                        }
                    }
                }
            }
        }
    }
    .export-temp {
        position: absolute;
        left: -9999px;
        top: 230px;
    }
}
