.install-font {
    background: #eee;
    height: 100%;
    overflow: hidden;
    margin-top: 2px;
    padding: 0px 20px 20px 20px;
    user-select: text;
    .installation-successful {
        margin-top: 20px;
        border: 1px solid #555;
        padding: 10px 15px;
        background: #efe;
        .title {
            font-size: 111%;
            padding-bottom: 4px;
        }
        .installed-font-example {
           border: 1px solid red;
           padding: 10px;
           background: white;
           font-size: 160%;
           overflow-wrap: break-word;
        }
        .actions {
            font-size: 120%;
            display: flex;
            justify-content: space-around;
            width: 600px;
            margin: 0 auto;
            padding: 10px 0 5px 0;
        }
    }

    .install-font-top {
        margin-top: 5px;
        padding: 8px 2px 0 2px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        .title {
            font-size: clamp(18px, 1.8vw, 20px);
            font-weight: bold;
        }
        .close-button {
            height: 28px;
            position: relative;
        }
    }

    .intro {
        //font-size: 125%;
        max-width: 1000px;
        min-height: 0vw;
        font-size: clamp(14px, 1vw, 18px);
        margin-top: 18px;
        padding: 0px 20px;
        background: #def;
        border: 1px solid rgba(0, 0, 0, 0.5);
        p {
            line-height: 120%;
            margin: 10px 0 10px 0;
            span {
                font-weight: bold;
            }
        }
        .url-example {
            color: green;
        }
    }

    .font-options-optional {
        margin-top: 20px;
        border: 1px solid #595;
        background: #efe;
        padding: 8px;
        p {
         margin: 8px 0;
        }
        .title {
            font-size: 110%;
            color: rgba(0, 200, 0, 0.8);
            text-shadow: 0 0 rgba(0, 0, 0, 0.5);
        }
        .font-options {
            display: flex;
        }
        p:first-child {
            margin-top: 11px;
        }
        .font-options {
            font-size: 90%;
            display: flex;
            width: 90%;
            margin: 12px auto 5px;
            justify-content: space-around;
            input[type="checkbox"] {
                margin-right: 3px;
            }
        }
        .restrict-to-characters {
            margin-top: 3px;
            textarea {
                width: 99%;
                height: 42px;
            }
        }
    }

    .main {
        background: #eee;
        margin-top: 30px;
        .show-error {
            color: red;
            text-align: center;
            margin-top: 20px;
        }
        .inputs {
            font-size: 120%;
            .font-url {
                display: flex;
                div {
                    font-size: 110%;
                    white-space: nowrap;
                    margin-right: 5px;
                }
                input[type="text"] {
                    width: 90%;
                    &.warning {
                        background: rgb(255, 200, 200);
                    }
                }
            }
        }

        .action-buttons {
            margin: 0 auto;
            width: 50%;
            padding-top: 20px;
            display: flex;
            justify-content: space-around;
        }
    }

    .font-obj {
        margin-top: 20px;
        background: #e4f3ff;
        border: 1px solid black;
        .install {
            padding: 10px;
            background: #beb;
            display: flex;
            font-size: 110%;
            line-height: 150%;
            button {
                margin-left: 16px;
            }
        }
        .font-data {
            padding: 10px;
            div {
                padding: 1px 0;
                span {
                    text-shadow: 0 0 rgb(3, 3, 158);
                    color: blue;
                }
                &.src {
                    div:nth-child(1) {
                        border: 1px solid black;
                        padding: 3px;
                        font-size: 90%;
                        color: blue;
                        height: 230px;
                        overflow-y: auto;
                        overflow-wrap: break-word;
                    }
                }
            }
        }
    }
}
