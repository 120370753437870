.grid-options {

    padding: 1px 25px 8px 05px;
    display: flex;

    .show-grid-text {
        color: #888;
        position: relative;
        top: -1px;
        white-space: nowrap;
        &.active {
            color: cyan;
        }
    }
    .switch {
        margin-left: 5px;
    }

    .slider-selection {
        display: flex;
        margin-left: 5%;
        .label {
            margin-right: 3px;
            position: relative;
            top: -1px;
            white-space: nowrap;
        }
        .slider-control-container {
            display: flex;
            padding: 0px 3px;
            .option-color {
              //  margin-top: 1px;
              position: relative;
              top: 0.05rem;
              font-size: 90%;
                color: #aaa;
                transition: all 100ms ease-in;
                &.selected {
                    color: cyan;
                }

            }
            .switch {
                margin-right: 5px;
            }
        }
        &.inactive {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
