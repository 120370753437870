.input-xy-position {
    font-size: 120%;
    .named {
        font-size: 100%;
        text-align: left;
        margin-left: 5px;
        margin-bottom: 4px;
        cursor: pointer;
    }
    .coordinate-container {
        display: flex;
        justify-content: center;
        span {
            margin-right: 5px;
            position: relative;
            top: -2px;
            cursor: pointer;
        }
        .slider {
            width: 232px;
        }
        .text-input-style {
            width: 38px;
            margin-left: 8px;
            font-size: 90%;
            height: 17px;
            &.warning {
                background-color: #faa;
            }
         }
        .coordinate-readout {
            font-size: 90%;
            width: 25px;
            margin-top: 1px;
            margin-left: 5px;
            text-align: right;
            color: #ccf;
        }
        .proportional-locker {
            width: 12px;
            height: 12px;
            white-space: nowrap;
            position: relative;
            margin-left: 3px;
            .top-l {
                position: relative;
                top: 2px;
                display: block;
                transform: scale(-1,-1);
            }
            .bottom-l {
                position: relative;
                top: -2px;
                display: block;
                transform: scaleX(-1);
            }
            
            span.proportional-control {
                //display: none;
                z-index: 1;
                position: absolute;
                top: 8px;
                left: -1px;
            }

        }
    }
}
