.sheet-counter-popper {
    background-color: #add;
    border-radius: 10%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 5px;
    box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.4);
    opacity: 0;
    animation: fadein 0.3s;
    animation-fill-mode: forwards;
    .sheet-counter-buttons {
        border: 1px solid #8aa;
        border-radius: 8%;
        background: #CCFFFF;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .action-button {
            margin: 10px 20px;
        }
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}