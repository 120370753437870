.input-slider {
    display: flex;
    justify-content: center;
    align-content: center;
    line-height: 17px;
    .named {
        font-size: 110%;
        margin-right: 2px;
        white-space: nowrap;
        cursor: pointer;
    }
    .value-readout {
        font-size: 110%;
        line-height: 18px;
        border: 1px solid black;
        text-align: right;
        padding: 0 2px 0 1px;
        color: #ccf;
        min-width: 31px;
        width: 31px;
        &.percent {
            min-width: 42px;
        }
        &.min-width {
            min-width: 22px;
            width: 22px;
        }
        &.use-x {
            margin-left: 3px;
            width: 40px;
            text-align: center;
        }
    }
    .slider {
        width: 200px;
        &.short {
            width: 180px;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
    .text-readout {
        white-space: nowrap;
        input[type="text"] {
            font-size: 110%;
            width: 30px;
            margin-left: 5px;
            &.warning {
                background-color: #faa;
            }
        }
        span.decoration {
            margin-left: 2px;
        }
    }
}
