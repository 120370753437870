.client {
    .opener {
        cursor: pointer;
    }
    &.opened {
        .drop-down { 
            display: block;
        }
    }
    .drop-down {
        display: none;
        margin-top: 1px;
        border-top: 1px solid #777;
        position: absolute;
        background-color: #e0e9ff;
        white-space: nowrap;
        z-index: 1;
        div {
            border-top: 1px solid #ddd;
            span {
                display: inline-block;
                padding: 3px 5px 3px 5px;
                pointer-events: none;
            }
            &:hover {
               background: #85fcff;
            }
        }
    }
}