.restore-workspace {
    display: inline-block;
    background: #eee;
    margin: 2px 3px;
    padding: 0px 10px 12px 10px;
    user-select: text;

    .restore-workspace-top {
        margin-top: 5px;
        padding: 8px 2px 0 2px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        .title {
            font-size: clamp(18px, 1.8vw, 20px);
            font-weight: bold;
        }
        .close-button {
            height: 28px;
            position: relative;
        }
    }
    .form-area {
        margin-left: 5px;
        display: flex;
        div:nth-child(1) {
            margin-top: 1px;
        }
        div:nth-child(n + 2) {
            margin-left: 10px;
        }
    }
    .restore-result {
        padding: 10px;
        color: #008800;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
        &.error {
            color: #a00;
        }
    }
}