.svg-button {
    width: 2.9em;
    margin: 0.57em;
    &.large-button {
        width: 9.1em;
        margin: 14px 7px 0 15px;
        .svg-code {
            width: 115px;
            height: 115px;
            padding: 10px;
            margin: 0 0 10px 0;
            >:first-child {
               display: block;
               margin: auto;
               height: 100%;
            }
        }
        .svg-name {
           width: 100%;
           word-break: break-all;
        }
    }
    .svg-code {
        display: flex;
        width: 50px;
        height: 50px;
        overflow: hidden;
        background: rgb(200, 200, 200);
        margin: 10px 6px;
        box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(80, 80, 80, 0.2);
        font-size: 20px;
        cursor: pointer;
        &.selected {
            background: rgb(190, 215, 255);
            box-shadow: inset 0.1em 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.2);
            box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(255, 255, 255, 0.3);
        }
        &:not(.selected):hover {
            background: rgb(240, 240, 255);
            //  box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(110, 80, 80, 0.2);
            box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.4);
        }
    }
    .svg-name {
        font-size: 90%;
        position: relative;
        top: -5px;
        width: 60px;
        text-align: center;
        &:not(.selected) {
            color: #aaa;
        }
    }
}
