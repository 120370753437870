.explode-dragger {
    width: 80%;
    height: 25px;
    top: 48%;
    position: relative;
    left: 56%;
    transform: rotate(270deg);
    z-index: 1101;
    input[type="range"] {
        transform: scale(150%);
        width: calc(60% - 10px);
        height: 50%;
    }
}
