.load-counter {
    position: relative;
    .action-button {
        &.submit-overwrite {
            width: 100px;
        }
    }
    .dialog {
        position: absolute;
        background-color: black;
        top: 30px;
        border: 1px solid rgba(0, 255, 255, 0.5);
        padding: 5px;
        z-index: 1;
        font-size: 110%;
        div {
            text-align: left;
        }
        .load-section {
            margin: 15px 8px 27px 8px;
            padding: 10px;
            border: 1px solid cyan;
            outline: 3px solid #055;
            position: relative;
            .load-section-title {
                position: absolute;
                background-color: black;
                top: -11px;
                padding: 0 6px 0 4px;
                font-size: 90%;
                font-style: italic;
                color: #0cc;
                span {
                    margin-left: 5px;
                    font-style: normal;
                }
            }
            .load-input-inline {
                padding: 7px 0 6px 4px;
                display: flex;
                white-space: nowrap;
                align-items: center;
                justify-content: center;
                div {
                    margin-right: 12px;
                }
            }
            .warning {
                color: #dd0;
                text-align: center;
                padding-top: 1px;
            }
            &.load-from-file {
                input[type="file"] {
                    position: relative;
                    left: 40px;
                    top: 4px;
                    margin: 6px 0 7px 0;
                }
            }
        }
    }
}
