.counter-menu {
   // min-width: 350px;
    width: 350px;
  //  width: 50vw;
    height: 100%;

    color: white;
    font-size: 30px;
    box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.1);
    //font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    position: relative;
    .option-menu-display-container {
        position: relative;
    .option-menu-display {
        background-image: linear-gradient(#007, #006, #005);
        
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1;
        width: 100%;
    }
}

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
    }
}
