.sheet {
    user-select: none;
    position: relative;
    .sheet-display {
        border: 1px dotted #ddd;
        box-shadow: 4px 4px 10px 2px rgba(0,0,0,0.3);
        position: relative;
        max-width: 1559px;
        .drag-layer {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
        }
    }
    .data-view {
        > div:first-child {
            font-size: 140%;
            color: white;
            margin-top: 10px;
            margin-bottom: 1px;
        }
        .active-layer-values {
            .results-table {
                border: 1px solid rgba(0, 0, 0, 0.4);
                margin-left: 20px;
                background-color: #777;
                tr:nth-child(even) {
                    background: #c5e5f5;
                }
                tr:nth-child(odd) {
                    background: #f0f000;
                }
                th,
                td {
                    padding: 3px 4px;
                }
                tr:first-child {
                    background: #ccc;
                }
            }
        }

        .state-data {
            text-align: left;
            background: #ddd;
            padding: 2px 5px;
            margin-top: 10px;
            span {
                font-size: 120%;
                color: #00a;
                margin: 5px;
            }
            .view-click {
                cursor: pointer;
                font-size: 18px;
            }
        }
    }
}
