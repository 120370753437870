.counter-draw {
    width: calc(100vw - 400px);
    height: calc(100vw - 400px);
    padding-bottom: 30px;
    max-width: 900px;
    max-height: 900px;
    min-width: 614px;
    min-height: 644px;
    margin-bottom: 30px;
    color: white;
    transition: 100 ease;
    .counter-draw-operations {
        .action-button {
            transform: scale(0.9);
            margin: 0 0.05rem;
            @media (min-width: 1000px) {
                transform: scale(1);
                margin: 0 0.2rem;
            }
            @media (min-width: 1200px) {
                transform: scale(1);
                margin: 0 0.4rem;
            }
            //margin: 0 0.2rem;
            position: relative;
            top: -1px;
            white-space: nowrap;
        }
    }
    > div:first-child {
        display: flex;
    }
    .counter-draw-container {
        position: relative;
        width: calc(100% - 10px);
        height: 100%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(0, 0, 255, 0.02);
        &.exploded {
            background: rgba(220, 220, 255, 0.2);
        }

        > div:nth-child(1) {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        > div:nth-child(2) {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        > div:nth-child(3) {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
