.input-text {
    text-align: left;
    margin-left: 5px;
    .named {
        font-size: 110%;
        margin-right: 5px;
    }
    input[type="text"] {
        width: 240px;
        font-size: 110%;
    }
}