body {
    background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px,
      radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
      radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
    background-color: #282828;
    background-size: 16px 16px;
    user-select: none;
}
.home {
    opacity: 0;
    position: relative;

    
    &.loaded {
        opacity: 1;
        transition: all 1s;
      }
    .flex-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        .main-box-1 {
            margin: 10px;
            display: flex;
            align-items: stretch;
            flex-grow: 2;
            flex-basis: 0%;
            // max-width: 1200px;
            // min-height: 710px;
            >div:nth-child(2) {
                margin-left: 10px;
                //min-width: 806px;
            }
        }
        .main-box-2 {
            background-image: linear-gradient(140deg, #dddddd 25%, #d9d9d9 25%, #d9d9d9 50%, #dddddd 50%, #dddddd 75%, #d9d9d9 75%, #d9d9d9 100%);
            background-size: 24.89px 20.89px;
           // min-width: 1000px;
            //max-width: 1310px;
            //height: 500px;
            margin: 10px;
            display: flex;
            align-items: stretch;
            justify-content: center;
            flex-grow: 1;
        }
    }
    .offscreen {
        position: absolute;
        left: -4000px;
        top: -4000px;
    }
}
