.modify-svg {
    margin: 20px;
    padding: 10px;
    background: white;
    user-select: text;
    > div:first-child {
        font-size: 110%;
        padding: 0 0 20px 0;
        display: flex;
        justify-content: space-between;
        a {
            text-decoration: none;
            color: blue;
        }
    }
    .section-title {
        font-size: 140%;
        font-style: italic;
        color: blue;
        &.second {
            margin-top: 20px;
            span {
                color: red;
                font-size: 80%;
                margin-left: 20px;
                cursor: pointer;
                font-style: normal;
            }
        }
    }

    .text-display-container {
        padding: 10px;
        .text-display {
            display: inline-block;
            background: white;
            padding: 10px;
            border: 1px solid black;
        }
    }
    .input-container {
        display: flex;
        padding: 10px;
        .form-container {
            padding: 10px;
            margin-right: 10px;
            border: 1px solid black;
            input[type="file"] {
                font-size: 108%;
            }
            .radio-group {
                display: flex;
                padding-top: 10px;
                > div:first-child {
                    margin-right: 5px;
                }
                label {
                    margin-right: 10px;
                }
            }
            .select-container {
                display: flex;
                margin-top: 10px;
                > div:first-child {
                    position: relative;
                    top: 4px;
                    margin-right: 5px;
                }
                > div:nth-child(3) {
                    position: relative;
                    top: 4px;
                    margin-left: 5px;
                }
                select {
                    font-size: 100%;
                    padding: 3px;
                }
                &.disabled {
                    opacity: 0.4;
                }
            }
            .actions {
                margin-top: 15px;
                button {
                    font-size: 105%;
                }
            }
            .text-container {
                display: flex;
                margin-top: 10px;
                > div:first-child {
                    position: relative;
                    top: 4px;
                    margin-right: 5px;
                }
                input[type="text"] {
                    width: 40px;
                    font-size: 110%;
                }
            }
        }
        .show-svg {
            display: inline-block;
            background: white;

            &.active {
                border: 1px solid black;
            }
        }
    }
    .modified-display {
        display: flex;
        padding: 10px;
        .bordered {
            border: 1px solid black;
        }
        .show-modified-svg {
            display: inline-block;
            background: white;
            border: 1px dotted blue;
            .action {
                text-align: center;
                padding-bottom: 20px;
                span {
                    margin: 0 10px 0 3px;
                    color: blue;
                }
            }
        }
        .text-modified-container {
            .text-modified {
                padding: 0 5px;
                margin-left: 10px;
                border: 1px solid black;
            }
        }
    }
    .centroid-center {
        .centroid-center-title {
            color: blue;

            font-size: 125%;
            padding: 10px 0 10px 0;
        }
        .centroid-center-box {
            margin-left: 10px;
            padding: 8px 0 10px 10px;
            border: 1px solid black;
            textarea {
                width: 90%;
            }
            .centroid-center-submit {
                margin-top: 10px;
            }
            .centroid-result {
                margin-top: 14px;
                padding: 5px;
                background: #d0eaff;
                border: 1px solid black;
                div {
                    padding: 5px;
                }
            }
        }
    }
}
